import GATSBY_COMPILED_MDX from "/Users/kundansingh/Documents/Hoomans/website/blog/right-agency/index.mdx";
import * as React from 'react';
import {graphql} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
const pageStyles = {
  color: "#232129",
  padding: "96px"
};
const imageStyle = {
  maxWidth: "50%"
};
const BlogPost = ({data, children}) => {
  const image = getImage(data.mdx.frontmatter.hero_image);
  return React.createElement(Layout, {
    pageTitle: data.mdx.frontmatter.title
  }, React.createElement("main", {
    style: pageStyles
  }, React.createElement("p", null, "Posted: ", data.mdx.frontmatter.date), React.createElement("p", null, "Author: ", data.mdx.frontmatter.author), React.createElement(GatsbyImage, {
    image: image,
    alt: data.mdx.frontmatter.hero_image_alt,
    style: imageStyle
  }), React.createElement("p", null, " Photo Credit:", " ", React.createElement("a", {
    href: data.mdx.frontmatter.hero_image_credit_link
  }, data.mdx.frontmatter.hero_image_credit_text)), children, React.createElement("p", null, "Last Edited On: ", data.mdx.parent.modifiedTime)));
};
export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        author
        date(formatString: "MMMM D, YYYY")
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      parent {
        ... on File {
          modifiedTime(formatString: "MMMM D, YYYY")
        }
      }
    }
  }
`;
export const Head = ({data}) => React.createElement(Seo, {
  title: data.mdx.frontmatter.title
});
BlogPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
